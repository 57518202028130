import { createContext, useContext } from 'react'

import { AttachmentDownloadMeta, ImageMetaDTO } from 'types/common/attachments'

export type KeysToFetch = keyof ImageMetaDTO

export type SignedResources = Record<AttachmentDownloadMeta['key'], AttachmentDownloadMeta['signedUrl']>

export interface BatchUrlsState {
  resources: SignedResources
  addResources: (resourcesKeys: ImageMetaDTO[], keysToFetch: KeysToFetch[]) => void
}

export type BatchUrlsContextType = BatchUrlsState

export const BatchUrlsContext = createContext<BatchUrlsContextType>(null!)

export const useBatchUrlsContext = () => useContext(BatchUrlsContext)
