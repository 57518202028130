import { TypographyType } from '@platform-ui-kit/components-library/dist/types/components/wpp-typography/types'
import { Tenant } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'

import { MayBeNull } from 'types/common/utils'
import { Tooltip } from 'ui-base/tooltip/Tooltip'

import * as S from 'ui-shared/madeByLabel/MadeByLabel.styled'

interface Props {
  tenant?: MayBeNull<Tenant>
  type?: TypographyType
}

export const MadeByLabel = ({ tenant, type = 'xs-body' }: Props) => {
  const { t } = useTranslation()

  if (!tenant?.id || !tenant?.name) {
    return <S.AgencyPlaceholder />
  }
  return (
    <Tooltip
      show
      showOnOverflow
      getInnerElement={el => el?.shadowRoot?.querySelector('[part="typography"]')}
      text={tenant?.name}
    >
      <S.Agency type={type} data-testid="agency-label">
        {t('by', { target: tenant?.name })}
      </S.Agency>
    </Tooltip>
  )
}
