import { PropsWithChildren, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMeasure, useSetState } from 'react-use'

import { useOnScreen } from 'hooks/useOnScreen'
import IntroImage from 'ui-shared/catalogueIntroSection/assets/IntroImage.png'

import * as S from 'ui-shared/catalogueIntroSection/CatalogueIntroSection.styled'

interface Props {
  title?: string
  imageUrl?: string
}

export const CatalogueIntroSection = ({ title, imageUrl, children }: PropsWithChildren<Props>) => {
  const { t } = useTranslation(['applications', 'common'])
  const { isIntersecting, ref: introPlaceholderRef } = useOnScreen(true)
  const defaultIntroHeight = 150 // default height of the element to pass to useMeasure so that page content doesn't shift on first render

  const [originalIntroSize, setOriginalIntroSize] = useSetState({ width: 0, height: defaultIntroHeight })
  const [introRef, { height: introHeight }] = useMeasure<HTMLDivElement>()

  useEffect(() => {
    if (!isIntersecting) return
    setOriginalIntroSize(({ height: prevHeight }) => ({
      height: Math.max(prevHeight, introHeight),
    }))
  }, [isIntersecting, introHeight, setOriginalIntroSize])

  return (
    <>
      <S.IntroPlaceholder
        ref={introPlaceholderRef}
        height={originalIntroSize.height}
        data-testid="intro-section-banner"
      />
      {isIntersecting ? (
        <S.Wrapper ref={introRef} data-testid="intro-section">
          <S.SectionContent direction="column" gap={22}>
            <S.SectionTitle tag="h2" type="3xl-heading" data-testid="intro-section-title">
              {title || t('applications|catalogue_intro_section_title')}
            </S.SectionTitle>
            {children}
          </S.SectionContent>
          <S.SectionImageWrapper>
            <S.Image url={imageUrl || IntroImage}></S.Image>
          </S.SectionImageWrapper>
        </S.Wrapper>
      ) : (
        <S.SearchWrapper isCollapsed={!isIntersecting}>{children}</S.SearchWrapper>
      )}
    </>
  )
}
