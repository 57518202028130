const SHOW_TAGS_NUM = 1
export const truncateTags = (translatedTags: string[], showTags = SHOW_TAGS_NUM) => {
  const truncatedNum = translatedTags.length - showTags
  const isTruncated = truncatedNum > 0
  const labelsArr = isTruncated ? [...translatedTags.slice(0, showTags), `+${truncatedNum}`] : translatedTags
  return {
    label: labelsArr.join(', '),
    fullLabel: translatedTags.join(', '),
    isTruncated,
  }
}
