import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react'

import { useGetDownloadUrlsApi } from 'api/attachments/queries/useGetDownloadUrlsApi'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { BatchUrlsContext, KeysToFetch, SignedResources } from 'providers/batchUrls/BatchUrlsContext'
import { BatchUrlContextState } from 'providers/batchUrls/BatchUrlsContextState'
import { AttachmentMeta, ImageMetaDTO } from 'types/common/attachments'
import { API } from 'types/common/enums'

export const BatchUrlsProvider = ({ children }: PropsWithChildren) => {
  const state = BatchUrlContextState.getInstance()

  const addResources = useCallback(
    (imageMetaDTOS: ImageMetaDTO[], keysToFetch: KeysToFetch[] = ['original', 'thumbnail']) => {
      state.resources = imageMetaDTOS.reduce((acc, imageMeta) => {
        keysToFetch.forEach(key => {
          const url = imageMeta[key]?.key as AttachmentMeta['key']
          acc.push(url)
        })
        return acc
      }, [] as AttachmentMeta['key'][])
    },
    [state],
  )

  const resourcesToFetch = state.resources
  const deferredResources = useDebouncedValue(resourcesToFetch, 1000)

  const { data: downloadMetas } = useGetDownloadUrlsApi({
    params: {
      api: API.MARKETPLACE,
      keys: deferredResources,
    },
    enabled: deferredResources.length > 0,
  })

  useEffect(() => {
    if (downloadMetas.length === 0) {
      return
    }
    state.signedResources = downloadMetas.reduce((acc, downloadMeta) => {
      acc[downloadMeta.key] = downloadMeta.signedUrl
      return acc
    }, {} as SignedResources)
  }, [downloadMetas, state])

  const signedResources = state.signedResources
  const batchUrlsValue = useMemo(
    () => ({
      resources: signedResources,
      addResources,
    }),
    [signedResources, addResources],
  )

  return <BatchUrlsContext.Provider value={batchUrlsValue}>{children}</BatchUrlsContext.Provider>
}
