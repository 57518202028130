import { QueryClient } from '@tanstack/react-query'

import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ApplicationDTO } from 'types/applications/application'

const getProperAttachmentMetas = (
  attachmentMetas: ApplicationDTO['attachmentMetas'],
): ApplicationDTO['attachmentMetas'] => {
  if (!attachmentMetas) {
    return null
  }
  return attachmentMetas
    .filter(attachmentMeta => Object.keys(attachmentMeta).length === 2)
    .filter(
      ({ original, thumbnail }) =>
        !!original && !!thumbnail && Object.keys(original).length > 2 && Object.keys(thumbnail).length > 2,
    )
}

const getProperVideoMetas = (videoMetas: ApplicationDTO['videoMetas']): ApplicationDTO['videoMetas'] => {
  if (!videoMetas) {
    return null
  }
  return videoMetas.filter(videoMeta => Object.keys(videoMeta).length > 0)
}

const getSortedBenefits = (benefits: ApplicationDTO['benefits']): ApplicationDTO['benefits'] => {
  if (!benefits) {
    return null
  }
  return [...(benefits || [])].sort((benefit1, benefit2) => benefit1.ordering - benefit2.ordering)
}

export const prepareApplication = (response: ApplicationDTO): ApplicationDTO => {
  return {
    ...response,
    benefits: getSortedBenefits(response.benefits),
    categories: response.categories || [],
    attachmentMetas: getProperAttachmentMetas(response.attachmentMetas),
    videoMetas: getProperVideoMetas(response.videoMetas),
  }
}

export const invalidateApplication = async (queryClient: QueryClient) => {
  await Promise.all(
    [
      ApiQueryKeys.APPLICATION_PREVIEW_RATED_REVIEW,
      ApiQueryKeys.APPLICATION_REVIEWS_PREVIEW,
      ApiQueryKeys.APPLICATION_RATING,
      ApiQueryKeys.APPLICATIONS_INFINITE,
    ].map(key => queryClient.invalidateQueries([key])),
  )
}
