import { WppGrid, WppSpinner } from '@platform-ui-kit/components-library-react'
import { RefCallback, useCallback, useState } from 'react'

import { useGetAllTenantsMap } from 'api/tenants/queries/useAllTenantsApi'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { ApplicationsSectionDesc } from 'pages/applications/list/components/ApplicationsSectionDesc'
import { DataBanner } from 'pages/applications/list/components/DataBanner'
import { AllCategoriesRequest, AppCategoryRequests, ApplicationsQuery } from 'pages/applications/list/utils'
import { useBatchUrlsContext } from 'providers/batchUrls/BatchUrlsContext'
import { ApplicationCategory } from 'types/applications/enums'
import { ImageMetaDTO } from 'types/common/attachments'
import { ApplicationCard } from 'ui-shared/applicationCard/ApplicationCard'

import * as S from 'pages/applications/list/ApplicationsListPage.styled'

interface Props {
  category: AppCategoryRequests
  query: ApplicationsQuery
  hasFilters?: boolean
}

export const ApplicationsSection = ({ category, query, hasFilters }: Props) => {
  const tenantsMap = useGetAllTenantsMap()
  const { data: applications, hasNextPage, isFetchingNextPage, fetchNextPage } = query
  const isSoloView = category === AllCategoriesRequest

  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [])

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isDisabled: !isSoloView,
  })

  const logos = applications.filter(({ logoMeta }) => !!logoMeta).map(({ logoMeta }) => logoMeta as ImageMetaDTO)
  const { addResources } = useBatchUrlsContext()
  addResources(logos, ['thumbnail'])

  return (
    <S.ApplicationsSection data-testid={category} hasFilters={hasFilters}>
      {!isSoloView && <ApplicationsSectionDesc hasNextPage={hasNextPage} category={category as ApplicationCategory} />}

      <S.ApplicationCardsWrapper container fullWidth rowSpacing={6} data-testid="application-cards-wrapper">
        {applications.map(application => (
          <WppGrid item all={12} xl={8} key={application.id}>
            <ApplicationCard
              application={application}
              tenant={tenantsMap[application.tenantId]}
              hideEmptyReviews={true}
            />
          </WppGrid>
        ))}
      </S.ApplicationCardsWrapper>
      {category === ApplicationCategory.DATA && <DataBanner />}
      {isSoloView && (
        <S.SpinnerBody justify="center" ref={setRef}>
          {isFetchingNextPage && <WppSpinner size="m" />}
        </S.SpinnerBody>
      )}
    </S.ApplicationsSection>
  )
}
