import { SignedResources } from 'providers/batchUrls/BatchUrlsContext'
import { AttachmentMeta } from 'types/common/attachments'
import { MayBeNull } from 'types/common/utils'

export class BatchUrlContextState {
  private _resourceKeys: AttachmentMeta['key'][] = []
  private _signedResources: SignedResources = {}

  private static instance: MayBeNull<BatchUrlContextState> = null

  private constructor() {}

  static getInstance() {
    if (!BatchUrlContextState.instance) {
      BatchUrlContextState.instance = new BatchUrlContextState()
    }
    return BatchUrlContextState.instance
  }

  get resources(): AttachmentMeta['key'][] {
    return this._resourceKeys
  }

  // Merge with resources in state
  set resources(newResources: AttachmentMeta['key'][]) {
    const fetchedKeys = Object.keys(this._signedResources)
    this._resourceKeys = [
      ...this._resourceKeys,
      ...newResources.filter(
        newResource => !fetchedKeys.includes(newResource) && !this._resourceKeys.includes(newResource),
      ),
    ]
  }

  get signedResources(): SignedResources {
    return this._signedResources
  }

  set signedResources(signedResources) {
    this.excludeSignedKeyFromResources(Object.keys(signedResources))
    this._signedResources = {
      ...this._signedResources,
      ...signedResources,
    }
  }

  private excludeSignedKeyFromResources(signedKeys: AttachmentMeta['key'][]) {
    this._resourceKeys = this._resourceKeys.filter(resource => !signedKeys.includes(resource))
  }
}
