import { WppTag } from '@platform-ui-kit/components-library-react'
import { Children, memo, PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ApplicationReviewScore, CombinedApplicationDTO } from 'types/applications/application'
import { ApplicationCategoryLabel } from 'types/applications/labels'
import { MayBeNull } from 'types/common/utils'
import { Tooltip } from 'ui-base/tooltip/Tooltip'
import { ApplicationCardReviews } from 'ui-shared/applicationCard/components/ApplicationCardReviews'
import { CertificationLevelSticker } from 'ui-shared/certificationLevelSticker/CertificationLevelSticker'
import { truncateTags } from 'utils/tags'

import * as S from 'ui-shared/applicationCard/ApplicationCard.styled'

interface Props {
  application: CombinedApplicationDTO
  reviewScore?: MayBeNull<ApplicationReviewScore>
  isScoreLoading?: boolean
  hideEmptyReviews?: boolean
  isBrief?: boolean
  dataTestId?: string
}

export const ApplicationCardFooter = memo(
  ({
    application,
    reviewScore,
    isScoreLoading,
    hideEmptyReviews,
    isBrief,
    dataTestId,
    children,
  }: PropsWithChildren<Props>) => {
    const { t } = useTranslation(['common', 'applications'])
    const { categories } = application

    const { overallReviewScore, reviewCount } = useMemo(() => {
      if (reviewScore) {
        return {
          overallReviewScore: reviewScore.overallScore,
          reviewCount: reviewScore.reviewCount,
        }
      }
      if (
        'overallReviewScore' in application &&
        !!application.overallReviewScore &&
        'reviewCount' in application &&
        !!application.reviewCount
      ) {
        return {
          overallReviewScore: application.overallReviewScore,
          reviewCount: application.reviewCount,
        }
      }
      return {
        overallReviewScore: null,
        reviewCount: null,
      }
    }, [reviewScore, application])

    const {
      label: categoriesLabel,
      fullLabel: fullCategoriesLabel,
      isTruncated: isCategoriesTruncated,
    } = useMemo(() => {
      const translated = categories.map(category =>
        t(`applications|application_categories.${ApplicationCategoryLabel[category]}.title`),
      )
      return truncateTags(translated)
    }, [categories, t])

    return (
      <S.ApplicationCardFooter data-testid={dataTestId}>
        {!!categories.length && (
          <Tooltip
            show={isCategoriesTruncated}
            text={fullCategoriesLabel}
            config={{ placement: 'bottom' }}
            data-testid="category-tooltip"
          >
            <WppTag label={categoriesLabel} variant="neutral" data-testid="application-category" />
          </Tooltip>
        )}

        {'certification' in application && application.certification && (
          <CertificationLevelSticker
            certificationLevel={application.certification}
            expectedReleaseDate={application.expectedReleaseDate}
            tagLike
          />
        )}

        {!isBrief && (!hideEmptyReviews || (hideEmptyReviews && reviewCount)) && (
          <ApplicationCardReviews
            overallReviewScore={overallReviewScore}
            reviewCount={reviewCount}
            isScoreLoading={isScoreLoading}
          />
        )}

        {!isBrief && Children.map(children, child => <>{child}</>)}
      </S.ApplicationCardFooter>
    )
  },
)
