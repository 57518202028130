import { WppPill, WppPillGroup } from '@platform-ui-kit/components-library-react'
import { useEffect, useRef, useState } from 'react'
import { useMeasure } from 'react-use'

import {
  CategoryCode,
  RemoveFilter,
  TagLabel,
} from 'pages/applications/list/components/filterTags/ApplicationFiltersTags'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'pages/applications/list/components/filterTags/ApplicationFiltersTags.styled'

type Props = {
  tags: TagLabel[]
  onClose: RemoveFilter
  shown: boolean
  setTagAmount: (amount: number) => void
}

const getTagsAmountForLine = (children: HTMLElement[]): number => {
  if (children.length === 0) {
    return 0
  }
  const tops = children.map(child => child.offsetTop).sort((a, b) => a - b)
  const firstTop = tops[0]
  let amount = 0
  for (const top of tops) {
    if (top !== firstTop) {
      return amount
    }
    amount++
  }
  return amount
}

export const ApplicationLineOfTags = ({ tags, onClose, shown, setTagAmount }: Props) => {
  const [localTags, setLocalTags] = useState<TagLabel[]>([])
  const [lineRef, { width }] = useMeasure<HTMLDivElement>()
  const hiddenRef = useRef<HTMLWppPillGroupElement | null>(null)

  useEffect(() => {
    const children = hiddenRef.current ? hiddenRef.current?.children[0].children : []
    const amount = getTagsAmountForLine(Array.from(children) as HTMLElement[])
    setLocalTags(tags.slice(0, amount))
    setTagAmount(amount)
  }, [width, tags, hiddenRef, setTagAmount])

  return (
    <S.LineOfTagsWrapper ref={lineRef} className={shown ? 'shown' : 'hidden'} data-testid="line-of-tags-wrapper">
      <S.HiddenTags ref={hiddenRef} type="display">
        <Flex wrap="wrap" align="center" gap={4}>
          {tags.map(tag => (
            <S.Tag key={tag.code} label={tag.label} removable />
          ))}
        </Flex>
      </S.HiddenTags>
      <WppPillGroup type="display">
        <Flex wrap="wrap" align="center" gap={4} data-testid="line-of-tags">
          {localTags.map(tag => (
            <WppPill
              key={tag.code}
              label={tag.label}
              onWppClose={() => onClose(tag.category as CategoryCode, tag.code)}
              data-testid={`${tag.label}-tag`}
              removable
            />
          ))}
        </Flex>
      </WppPillGroup>
    </S.LineOfTagsWrapper>
  )
}
