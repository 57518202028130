import { WppPillGroup } from '@platform-ui-kit/components-library-react'
import { ParseKeys } from 'i18next'
import { useTranslation } from 'react-i18next'

import { RemoveFilter, TagLabel } from 'pages/applications/list/components/filterTags/ApplicationFiltersTags'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'pages/applications/list/components/filterTags/ApplicationFiltersTags.styled'

type Props = {
  message: ParseKeys<['applications']>
  tags: TagLabel[]
  onClose: RemoveFilter
  group: string
}

export const ApplicationTagsGroup = ({ message, tags, onClose, group }: Props) => {
  const { t } = useTranslation(['applications'])

  return (
    <Flex direction="column" gap={8} data-testid={`group-${group}`}>
      <S.TagsGroupTypography type="s-strong" data-testid="group-name">
        {t(message)}
      </S.TagsGroupTypography>
      <WppPillGroup type="display">
        <Flex wrap="wrap" align="center" gap={4}>
          {tags.map(tag => (
            <S.Tag
              key={tag.code}
              label={tag.label}
              onWppClose={() => onClose(tag.category, tag.code)}
              data-testid={`${tag.category}-tag`}
              removable
            />
          ))}
        </Flex>
      </WppPillGroup>
    </Flex>
  )
}
