import { WppAccordion, WppTypography } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'

import * as S from 'pages/applications/list/ApplicationsListPage.styled'

interface Props<Option, Value> extends Omit<ComponentProps<typeof WppAccordion>, 'onChange'> {
  filterTitle: string
  options: Option[]
  value: Value[]
  onChange: (newValue: Value[]) => unknown
}

type BaseOption = Record<string, any>
type BaseValue = string | number

export const ApplicationSelectableCardsFilter = <Option extends BaseOption, Value extends BaseValue>({
  filterTitle,
  value,
  options,
  onChange,
  ...rest
}: Props<Option, Value>) => {
  const handleSingleCardGroupChange = (option: Option) => {
    const newValue = value.some(val => val === option.value)
      ? value.filter(val => val !== option.value)
      : [...value, option.value as Value]
    onChange(newValue)
  }

  return (
    <S.FiltersAccordion size="m" expandedByDefault {...rest}>
      <WppTypography type="s-strong" slot="header">
        {filterTitle}
      </WppTypography>
      <S.FilterBody direction="column" gap={12}>
        <S.FilterControlWrapper>
          <S.CheckboxCardGroup withRadioOrCheckbox={false} value={value}>
            {options.map(option => (
              <S.CheckboxCard
                data-testid={`${option.value}-card`}
                value={option.value}
                key={option.value}
                onClick={() => handleSingleCardGroupChange(option)}
              >
                <S.CheckboxCardLabel type="s-body">{option.label}</S.CheckboxCardLabel>
              </S.CheckboxCard>
            ))}
          </S.CheckboxCardGroup>
        </S.FilterControlWrapper>
      </S.FilterBody>
    </S.FiltersAccordion>
  )
}
