import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import { useCallback, useEffect, useState } from 'react'

import { SignedResources, useBatchUrlsContext } from 'providers/batchUrls/BatchUrlsContext'
import { ApplicationDTO } from 'types/applications/application'
import { Logo, LogoPlaceholder, LogoProps, LogoSize, LogoWrapper } from 'ui-base/svgIcons/logo/Logo'

interface Props extends Pick<ApplicationDTO, 'logoMeta'>, Pick<LogoProps, 'size' | 'isCircle'> {
  customResources?: SignedResources
}

export const ApplicationLogoPlain = ({ logoMeta, size = 's', customResources }: Props) => {
  const [imageSource, setImageSource] = useState<string>('')
  const [imageNotFound, setImageNotFound] = useState<boolean>(false)
  const batchUrlsContext = useBatchUrlsContext()
  const resources = batchUrlsContext?.resources || customResources
  const logoUrl = logoMeta?.thumbnail?.key && resources ? resources[logoMeta.thumbnail.key] : ''

  const onLoad = useCallback(() => {
    setImageSource(logoUrl)
  }, [logoUrl])

  const onError = useCallback(() => {
    setImageNotFound(true)
  }, [])

  useEffect(() => {
    if (!logoUrl) {
      return
    }
    const imageElement = new Image()
    imageElement.src = logoUrl
    imageElement.addEventListener('load', onLoad)
    imageElement.addEventListener('error', onError)
    return () => {
      imageElement.removeEventListener('load', onLoad)
      imageElement.removeEventListener('error', onError)
    }
  }, [imageSource, logoUrl, onError, onLoad])

  if (!logoUrl || imageNotFound) {
    return <LogoPlaceholder size={size} data-testid="logo-placeholder" />
  }

  return (
    <LogoWrapper size={size}>
      {!imageSource && <WppSkeleton animation width={LogoSize[size]} height={LogoSize[size]} />}
      {!!imageSource && <Logo src={imageSource} size={size} alt="Application logo" data-testid="product-logo-img" />}
    </LogoWrapper>
  )
}
