import styled from '@emotion/styled'
import { WppTypography } from '@platform-ui-kit/components-library-react'

export const AgencyPlaceholder = styled('div')`
  height: 20px;
`

export const Agency = styled(WppTypography)`
  color: var(--wpp-grey-color-800);
`
