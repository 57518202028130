import { WppButton } from '@platform-ui-kit/components-library-react'
import { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import { ApplicationsSection } from 'pages/applications/list/components/ApplicationsSection'
import { AppCategoryRequests, AppsQueriesMap } from 'pages/applications/list/utils'
import { useCatalogueFilters } from 'pages/catalogue/main/CatalogueFiltersContext'
import { BatchUrlsProvider } from 'providers/batchUrls/BatchUrlsProvider'
import { EmptyState } from 'ui-base/emptyState/EmptyState'
import { CustomWppSpinner } from 'ui-base/spinner/Spinner'

interface Props extends HTMLAttributes<HTMLDivElement> {
  appsQueriesMap: AppsQueriesMap
  isLoading: boolean
  resultsNumber: number
  hasFilters: boolean
}

export const ApplicationsContent = ({ appsQueriesMap, isLoading, resultsNumber, hasFilters }: Props) => {
  const { t } = useTranslation(['errors', 'common'])
  const { resetAppFilters } = useCatalogueFilters()

  if (isLoading) {
    return <CustomWppSpinner size="l" data-testid="applications-loading-spinner" />
  }

  if (!resultsNumber && !isLoading) {
    return (
      <EmptyState
        data-testid="applications-empty-state"
        message={{
          title: t(hasFilters ? 'errors|no_results.title' : 'errors|no_applications.title'),
          subtitle: t(hasFilters ? 'errors|no_results.subtitle' : 'errors|no_applications.subtitle'),
        }}
        showImage
        margin="100px 0"
      >
        {hasFilters && (
          <WppButton onClick={resetAppFilters} data-testid="clear-all-btn">
            {t('common|clear_all')}
          </WppButton>
        )}
      </EmptyState>
    )
  }

  return (
    <BatchUrlsProvider>
      {Object.entries(appsQueriesMap)
        .filter(([, { data }]) => !!data.length)
        .map(([category, query]) => (
          <ApplicationsSection
            key={category}
            category={category as AppCategoryRequests}
            query={query}
            hasFilters={hasFilters}
          />
        ))}
    </BatchUrlsProvider>
  )
}
