import { WppTag, WppTooltip } from '@platform-ui-kit/components-library-react'
import { HTMLAttributes, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { ApplicationDTO } from 'types/applications/application'
import { ApplicationCategory } from 'types/applications/enums'
import { Flex } from 'ui-base/flex/Flex'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  categories: ApplicationCategory[]
  isAvailable: ApplicationDTO['isAvailable']
}

export const ApplicationCardActions = ({ categories, isAvailable, children, ...rest }: Props) => {
  const { t } = useTranslation(['applications'])
  const showAvailable = categories.length > 0 && isAvailable

  return (
    <div {...rest}>
      <Flex gap={showAvailable && !!children ? 4 : 0} direction="row" align="center">
        {showAvailable && (
          <WppTooltip text={t('applications|available_tooltip')} config={{ placement: 'right' }}>
            <WppTag label={t('applications|available')} variant="positive" data-testid="label-available" />
          </WppTooltip>
        )}
        {children}
      </Flex>
    </div>
  )
}
